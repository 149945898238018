/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';

import {
  Collapse,
  Col,
  Row,
  Empty,
} from 'antd';

import SoundSessionAttachmentWav from './SoundSessionAttachmentWav';
import SoundSessionAttachmentDetector from './SoundSessionAttachmentDetector';
import SoundSessionAttachmentReverberation from './SoundSessionAttachmentReverberation';

const SoundSessionAttachmentList = (props) => {
  const {
    attachments,
    timezone,
  } = props;

  if (!attachments || attachments.length === 0) {
    return (
      <Row>
        <Col xs={24}>
          <Empty />
        </Col>
      </Row>
    );
  }

  return (
    <Row>
      <Col xs={24}>
        <Collapse>
          {attachments.map((attachment, index) => (
            <Collapse.Panel header={attachment.name} key={index.toString()}>
              {attachment.type === 'WAV' && (
                <SoundSessionAttachmentWav
                  attachment={attachment}
                  timezone={timezone}
                />
              )}
              {attachment.type === 'DETECTOR' && (
                <SoundSessionAttachmentDetector
                  attachment={attachment}
                  timezone={timezone}
                />
              )}
              {attachment.type === 'REVERBERATION' && (
              <SoundSessionAttachmentReverberation
                attachment={attachment}
                timezone={timezone}
              />
              )}
            </Collapse.Panel>
          ))}
        </Collapse>
      </Col>
    </Row>
  );
};

const propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  attachments: PropTypes.any.isRequired,
  timezone: PropTypes.string.isRequired,
};

SoundSessionAttachmentList.propTypes = propTypes;

export default SoundSessionAttachmentList;
