/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';

import {
  Table,
} from 'antd';

import { useTranslation } from 'react-i18next';
import ReactAudioPlayer from 'react-audio-player';
import { formatLocaleWithTimezone, getLocale } from '../../utils/date';

const SoundSessionAttachmentWav = (props) => {
  const {
    attachment,
    timezone,
  } = props;

  const { t, i18n } = useTranslation('translations');
  const locale = getLocale(i18n.language);

  const metadata = React.useMemo(() => {
    const rows = Object.keys(attachment.metadata).map((key) => {
      let value = attachment.metadata[key];
      if (key === 'fromTimestamp') {
        return {
          parameter: 'from',
          value: formatLocaleWithTimezone(value, 'yyyy-MM-dd HH:mm:ss.SSS', locale, timezone),
        };
      }
      if (key === 'toTimestamp') {
        return {
          parameter: 'to',
          value: formatLocaleWithTimezone(value, 'yyyy-MM-dd HH:mm:ss.SSS', locale, timezone),
        };
      }
      if (Array.isArray(value)) {
        value = value.join(',');
      }
      return {
        parameter: key,
        value,
      };
    });
    return rows;
  }, [attachment]);

  const columns = React.useMemo(() => {
    const result = [
      {
        title: t('common.parameter'),
        dataIndex: 'parameter',
        width: '50%',
      },
      {
        title: t('common.value'),
        dataIndex: 'value',
      },
    ];
    return result;
  }, [t]);

  return (
    <>
      <ReactAudioPlayer
        style={{ width: '100%' }}
        src={attachment.url}
        controls
      />
      <Table
        bordered
        size="small"
        scroll={{ x: true }}
        columns={columns}
        rowKey={(record) => record.parameter}
        dataSource={metadata}
        pagination={false}
      />
    </>
  );
};

const propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  attachment: PropTypes.any.isRequired,
  timezone: PropTypes.string.isRequired,
};

SoundSessionAttachmentWav.propTypes = propTypes;

export default SoundSessionAttachmentWav;
