import React from 'react';

import {
  Tabs,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { useParams, useSearchParams } from 'react-router-dom';
import { ComponentLoader, messageError, PageSection } from '@deltaohm/ant-components';
import { useSoundSession } from './hooks';
import { extractGraphQLErrors } from '../utils/graphql';
import { ErrorList } from '../generics';

import {
  SoundSessionPropertyTab,
  SoundSessionMeasuresTab,
  SoundSessionSetupTab,
  SoundSessionAttachmentTab,
} from './tabs';

const { TabPane } = Tabs;

const SoundSessionDetailPage = () => {
  const { workspaceId, soundSessionId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation();

  const {
    error,
    loading,
    workspace,
    soundSession,
  } = useSoundSession(workspaceId, soundSessionId);

  const breadcrumb = React.useMemo(() => {
    const result = [
      {
        path: '/workspaces',
        breadcrumbName: 'Workspaces',
      },
    ];
    if (workspace) {
      result.push({
        path: `/workspaces/${workspaceId}`,
        breadcrumbName: workspace.name,
      });
      if (soundSession) {
        result.push({
          path: `/workspaces/${workspaceId}/soundSessions/${soundSessionId}}`,
          breadcrumbName: soundSession.name,
        });
      }
    }
    return result;
  }, [workspace, soundSession]);

  React.useEffect(() => {
    if (error) {
      const errors = extractGraphQLErrors(error, t);
      messageError({
        content: <ErrorList errors={errors} />,
      });
    }
  }, [error, t]);

  const activeTab = React.useMemo(() => {
    const tab = searchParams.get('tab');
    if (!tab) {
      return 'measures';
    }
    return tab;
  }, [searchParams]);

  const handleChangeTab = React.useCallback((newTab) => {
    setSearchParams({ tab: newTab });
  }, [setSearchParams]);

  return (
    <ComponentLoader
      message={t('common.loading')}
      loading={loading}
    >
      <PageSection
        title={soundSession ? soundSession.name : ''}
        breadcrumb={breadcrumb}
      >
        <Tabs activeKey={activeTab} onChange={handleChangeTab}>
          <TabPane tab={t('soundSessions.detail.tabs.measures')} key="measures">
            {soundSession && (
              <SoundSessionMeasuresTab
                workspaceId={workspaceId}
                soundSession={soundSession}
              />
            )}
          </TabPane>
          <TabPane tab={t('soundSessions.detail.tabs.setup')} key="setup">
            {soundSession && (
              <SoundSessionSetupTab
                soundSession={soundSession}
              />
            )}
          </TabPane>
          <TabPane tab={t('soundSessions.detail.tabs.properties')} key="properties">
            {soundSession && (
              <SoundSessionPropertyTab
                workspaceId={workspaceId}
                soundSession={soundSession}
              />
            )}
          </TabPane>
          <TabPane tab={t('soundSessions.detail.tabs.attachments')} key="attachments">
            {soundSession && (
              <SoundSessionAttachmentTab
                workspaceId={workspaceId}
                soundSession={soundSession}
              />
            )}
          </TabPane>
        </Tabs>
      </PageSection>
    </ComponentLoader>
  );
};

export default SoundSessionDetailPage;
