import React from 'react';
import PropTypes from 'prop-types';

import {
  Row,
  Col,
} from 'antd';

import {
  PageSection,
} from '@deltaohm/ant-components';

import { useTranslation } from 'react-i18next';
import { SoundSessionAttachmentList } from '../../soundSessionAttachments/components';

const SoundSessionAttachmentTab = (props) => {
  const {
    soundSession,
  } = props;

  const { t } = useTranslation();

  return (
    <PageSection
      title={t('soundSessions.attachmentTab.title')}
    >
      <Row>
        <Col xs={24}>
          <SoundSessionAttachmentList
            attachments={soundSession.attachments}
            timezone={soundSession.timezone}
          />
        </Col>
      </Row>
    </PageSection>
  );
};

const propTypes = {
  soundSession: PropTypes.shape({
    attachments: PropTypes.any.isRequired,
    timezone: PropTypes.string.isRequired,
  }).isRequired,
};

SoundSessionAttachmentTab.propTypes = propTypes;

export default SoundSessionAttachmentTab;
